<template>
    <picture>
        <source v-if="selfHosted && !isSvg" :srcset="sourceSet" type="image/webp" />
        <img v-bind="imageProps" :class="imageClass" :loading="lazyLoad ? 'lazy' : 'eager'" />
    </picture>
</template>
<script setup lang="ts">
const props = defineProps({
    src: { type: String, required: true },
    cssClass: { type: String, default: null },
    alt: { type: String, default: null },
    title: { type: String, default: null },
    height: { type: String, default: null },
    width: { type: String, default: null },
    id: { type: String, default: null },
    lazyLoad: { type: Boolean, default: true },
    multiResolution: {
        // Enabling multiResolution requires that selfHosted is true, this is so
        // we can use the same component for external or selfHosted images.
        // if multiResolution == true && selfHosted == true, we can assume the
        // higher resolution versions of the image are available.
        type: Boolean,
        default: false,
    },
    selfHosted: {
        // This component was originally used to upgrade jpg company images
        // to webp so the default value is true, however, this component
        // is now used as well in places where the image may be selfHosted
        // or it may come from a third-party site. If the image is not
        // selfHosted, we must use the exact image URL specified in the data.
        // If the image is selfHosted, we can assume the webp version is
        // available.
        type: Boolean,
        default: true,
    },
});

const imageProps = computed(() => {
    const { id, alt, title, width, height } = props;
    let src = "";
    if (isValidImage.value) {
        src = isValidFlexImage.value ? `${props.src}?w=${props.width}&h=${props.height}` : props.src;
    }

    return { id, alt, title, width, height, src };
});

const sourceSet = computed(() => {
    if (!isValidImage.value) return "";
    if (props.selfHosted) {
        if (isSvg.value) {
            return props.src;
        } else if (isValidFlexImage.value) {
            //CDN auto generates the other resolutions
            const iWidth: number = parseInt(props.width, 10);
            const iHeight: number = parseInt(props.height, 10);
            const w1_5 = Math.floor(iWidth * 1.5);
            const h1_5 = Math.floor(iHeight * 1.5);
            const w2_0 = Math.floor(iWidth * 2);
            const h2_0 = Math.floor(iHeight * 2);
            return `${props.src}?w=${iWidth}&h=${iHeight}, ${props.src}?w=${w1_5}&h=${h1_5} 1.5x, ${props.src}?w=${w2_0}&h=${h2_0} 2x`;
        } else if (isFlexImage.value) {
            // This is a flex image with no dimensions, so fallback to the original file
            return props.src;
        } else if (props.multiResolution) {
            return `${props.src}.webp, ${props.src}.1.5x.webp 1.5x, ${props.src}.2.0x.webp 2x`;
        } else {
            return `${props.src}.webp`;
        }
    } else {
        return props.src;
    }
});

const svgRegEx = /\.svg$/;
const flexRegEx = /^\/i\//;

const imagePath = computed(() => (PageHelpers.IsValidUrl(props.src) ? new URL(props.src)?.pathname || "" : ""));
const isSvg = computed(() => svgRegEx.test(props.src));
const isFlexImage = computed(() => flexRegEx.test(imagePath.value));
const isValidImage = computed(() => imagePath.value != "");
const isValidFlexImage = computed(() => isFlexImage.value && props.height && props.width);

const imageClass = computed(() => cn("object-scale-down", props.cssClass));
</script>
